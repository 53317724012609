/**
 * Return real width for a DOM Node
 * @param {HTMLElement} el
 * @returns {number}
 */
export const outerWidth = el => {
    const style = getComputedStyle(el)
    const width = style.getPropertyValue('width') || 0
    const marginLeft = style.getPropertyValue('margin-left') || 0
    const marginRight = style.getPropertyValue('margin-right') || 0

    return parseInt(width) + parseInt(marginLeft) + parseInt(marginRight)
}

/**
 * Return real height for a DOM Node
 * @param {DOM Node} el
 * @returns {number}
 */
export const outerHeight = el => {
    const style = getComputedStyle(el)
    const height = style.getPropertyValue('height') || 0
    const marginTop = style.getPropertyValue('margin-top') || 0
    const marginBottom = style.getPropertyValue('margin-bottom') || 0

    return parseInt(height) + parseInt(marginTop) + parseInt(marginBottom)
}

export const appendSchema = content => {
    const el = window.document.createElement('script')
    el.setAttribute('type', 'application/ld+json')
    el.innerHTML = JSON.stringify(content)
    window.document.head.appendChild(el)
}
